import { useQuery } from '@tanstack/react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/axiosInstance';

const getDeliveryHours = async payload => {
  const response = await axios.get(ENDPOINT.DELIVERY_HOURS, {
    params: { ...payload },
    errorHandle: false,
  });

  return response?.data ?? {};
};

const useDeliveryHours = (payload, options = {}) => {
  return useQuery({
    queryKey: ['deliveryHours', payload],
    queryFn: () => getDeliveryHours(payload),
    initialStale: true,
    ...options,
  });
};

export default useDeliveryHours;
