import { useTranslation } from 'next-i18next';

import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import Button from '@components/elements/Button';
import Modal from '@components/elements/Modal/Modal';
import TAB_SLUGS from '@constants/tabSlugs';
import { selectOrderTabsPick } from '@features/orderTabs/orderTabsSlice';
import useDeleteUserAddress from '@hooks/useDeleteUserAddress';
import useMediaQuery from '@hooks/useMediaQuery';
import UserService from '@services/User.service';
import { down } from '@utils/screens';

import Address from './Address';
import AddressModal from './AddressModal';

const AddressesList = ({
  isFetched = false,
  addresses = [],
  onClickAddress,
  onSuccessAddAddress,
  onSuccessUpdateAddress,
  canAddNewAddress = false,
  selectedAddresses,
  styles = {},
  allowStickyNextButton,
}) => {
  const { t } = useTranslation();
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isDeliveryAddressModalOpen, setIsDeliveryAddressModalOpen] =
    useState(false);
  const [typeModal, setTypeModal] = useState('editAddress');
  const [editAddress, setEditAddress] = useState({});
  const [deleteAddressId, setDeleteAdressId] = useState(0);
  const { mutate: deleteUserAddress, isLoading: deleteAddresIsLoading } =
    useDeleteUserAddress();
  const isDownMd = useMediaQuery(down('md'));
  const openAddressModal = () => setIsAddressModalOpen(true);
  const closeAddressModal = () => setIsAddressModalOpen(false);
  const closeDeliveryAddressModal = () => setIsDeliveryAddressModalOpen(false);

  const isEditAddress = typeModal === 'editAddress';
  const isAddAddress = typeModal === 'addAddress';
  const button = document.querySelector('#order-form-next-button');

  const currentStepSlug = useSelector(state =>
    selectOrderTabsPick(state, ['currentStepSlug'])
  );

  useEffect(() => {
    if (
      !isAddressModalOpen &&
      currentStepSlug === TAB_SLUGS.DELIVERY_DATA &&
      !allowStickyNextButton &&
      isDownMd
    ) {
      window.scroll({
        behavior: 'smooth',
        top: button?.offsetTop - window?.innerHeight + 55,
      });
    }
  }, [isAddressModalOpen]);

  useEffect(() => {
    if (isFetched && isEmpty(addresses)) {
      handleClickAddAddress();
    }
  }, [isFetched]);

  const handleClickEditAddress = (e, address) => {
    e.stopPropagation();
    setTypeModal('editAddress');
    setEditAddress(address);
    openAddressModal();
  };

  const handleClickDeleteAddress = (e, addressId) => {
    e.stopPropagation();
    setDeleteAdressId(addressId);
    deleteUserAddress(addressId);
  };

  const handleClickAddAddress = () => {
    setTypeModal('addAddress');
    setEditAddress({});
    openAddressModal();
  };

  const handleSuccessAddAddress = address => {
    if (onSuccessAddAddress) {
      onSuccessAddAddress(address);
    }
    closeAddressModal();
  };

  const handleSuccessUpdateAddress = address => {
    if (onSuccessUpdateAddress) {
      onSuccessUpdateAddress(address);
    }
    closeAddressModal();
  };

  const handleSetAddressToAllDelivery = () => {
    closeDeliveryAddressModal();
  };

  if (isFetched && isEmpty(addresses)) {
    return (
      <Fragment>
        <p>
          {t(
            '$*components.addressesList.noAdress.paragraph',
            'Nie posiadasz jeszcze żadnego adresu dostawy kliknij przycisk poniżej, aby dodać adres domyślny.'
          )}
        </p>

        <Button
          onClick={handleClickAddAddress}
          variant="outlined"
          fullWidth={true}
          styles={{ button: tw`mt-5` }}
          data-cy="addresses-list__add-new"
        >
          {t('$*components.addressesList.addAddress', '+ Dodaj adres')}
        </Button>

        <AddressModal
          address={editAddress}
          isAddAddress={isAddAddress}
          isEditAddress={isEditAddress}
          isFirstAddress={true}
          isNewAddress={isAddAddress}
          isOpen={isAddressModalOpen}
          onClose={closeAddressModal}
          onSuccessAddAddress={handleSuccessAddAddress}
          onSuccessUpdateAddress={handleSuccessUpdateAddress}
        />
      </Fragment>
    );
  }

  return (
    <div>
      <div tw="flex -m-3 flex-wrap" data-cy="addresses-list">
        {UserService.sortAddressesByDefaultFirst(addresses).map(
          (address, index) => {
            return (
              <Address
                key={address.id}
                itemNumber={index + 1}
                address={address}
                deleteAddressId={deleteAddressId}
                selectedAddresses={selectedAddresses}
                onClickAddress={onClickAddress}
                onClickEditAddress={handleClickEditAddress}
                onClickDeleteAddress={handleClickDeleteAddress}
                deleteAddresIsLoading={deleteAddresIsLoading}
                styles={styles?.address}
              />
            );
          }
        )}
      </div>

      {canAddNewAddress && (
        <Button
          onClick={handleClickAddAddress}
          variant="outlined"
          fullWidth={true}
          styles={{ button: tw`mt-8` }}
          data-cy="addresses-list__add-new"
        >
          {t('$*components.addressesList.addAddress', '+ Dodaj adres')}
        </Button>
      )}

      <AddressModal
        address={editAddress}
        isAddAddress={isAddAddress}
        isEditAddress={isEditAddress}
        isNewAddress={isAddAddress}
        isOpen={isAddressModalOpen}
        onClose={closeAddressModal}
        onSuccessAddAddress={handleSuccessAddAddress}
        onSuccessUpdateAddress={handleSuccessUpdateAddress}
      />

      <Modal
        isOpen={isDeliveryAddressModalOpen}
        onClose={closeDeliveryAddressModal}
        desktopSize="lg"
      >
        <Modal.Header>
          {t(
            '$*components.addressesList.modal.title.changeDelivery',
            'Zmiana adresu dostaw'
          )}
        </Modal.Header>
        <Modal.Content>
          <p>
            {t(
              '$*components.addressesList.modal.content.changeDelivery',
              'Czy chcesz zmienić adres dla wszystkich aktywnych dostaw?'
            )}
          </p>
        </Modal.Content>
        <Modal.Footer>
          <Button color="default" size="sm" onClick={closeDeliveryAddressModal}>
            {t('$*common.cancel', 'Anuluj')}
          </Button>
          <Button
            color="success"
            size="sm"
            // isLoading={isLoading}
            onClick={handleSetAddressToAllDelivery}
          >
            {t('$*common.confirm', 'Potwierdź')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddressesList;
