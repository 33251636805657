import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@store/auth/auth.slice';
import { useQuery } from '@tanstack/react-query';
import type { Address } from 'types/Address';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/axiosInstance';

const getUserAddresses = async () => {
  const response = await axios.get(ENDPOINT.DELIVERY_ADDRESSES);

  return response?.data?.['hydra:member'] ?? [];
};

const useUserAddresses = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return useQuery<Address[]>({
    queryKey: ['userAddresses'],
    queryFn: getUserAddresses,
    enabled: isAuthenticated,
  });
};

export default useUserAddresses;
