import { useTranslation } from 'next-i18next';

import tw from 'twin.macro';

import AddressLineInfo from '@components/elements/AddressLineInfo';
import Button from '@components/elements/Button';
import { transformDeliveryHourToString } from '@services/Api.service';

const Address = ({
  address,
  deleteAddresIsLoading,
  itemNumber,
  onClickAddress,
  onClickDeleteAddress,
  onClickEditAddress,
  selectedAddresses,
  deleteAddressId,
  styles = {},
}) => {
  const { t } = useTranslation();
  const canDeleteAddress = ({ containsActiveDiets, isDefault, id }) => {
    return (
      !containsActiveDiets && !isDefault && !selectedAddresses.includes(id)
    );
  };

  const isSelected = selectedAddresses.includes(address.id);
  const isAdressNotSupported = !address?.zone ?? false;
  const deliveryHourString = transformDeliveryHourToString({
    hour: address.selectedDeliveryHour,
    useDefaultTranslations: true,
    t,
  });

  return (
    <div
      tw="p-3 flex-auto w-full"
      css={styles?.wrapper}
      onClick={() => {
        if (!isAdressNotSupported && onClickAddress) {
          onClickAddress(address);
        }
      }}
      data-cy="address"
    >
      <div
        css={[
          tw`relative flex flex-col w-full h-full border-2 border-gray-1 rounded-lg cursor-pointer`,
          isSelected && tw`border-primary`,
        ]}
      >
        <div
          css={[
            tw`flex justify-between px-3 py-1 text-sm font-semibold uppercase bg-gray-1`,
            isSelected && tw`text-white bg-primary`,
          ]}
        >
          <div>
            {t('$*components.addressesList.adressName', {
              defaultValue: 'Adres {{number}} ',
              replace: { number: itemNumber, isDefault: true },
            })}
            {address.isDefault && (
              <span tw="ml-1">
                ({t('$*components.addressesList.isDefaultAddress')})
              </span>
            )}
          </div>
          {isAdressNotSupported && (
            <div>{t('$*common.unsupported', 'Nieobsługiwany')}</div>
          )}
        </div>
        <div tw="px-3 py-2 flex-auto flex flex-col">
          <AddressLineInfo {...address} mode="CITY_FIRST_NO_POSTCODE" />
          <div>{deliveryHourString}</div>

          <div tw="flex justify-between pt-4 mt-auto">
            <Button
              onClick={e => onClickEditAddress(e, address)}
              variant="link"
              styles={{ button: tw`text-sm font-semibold uppercase` }}
              data-cy="address__button-edit"
            >
              {t('$*common.edit', 'Edytuj')}
            </Button>

            {canDeleteAddress(address) && (
              <Button
                variant="link"
                color="error"
                styles={{
                  button: tw`text-sm font-semibold uppercase`,
                  spinner: tw`w-4 ml-0 mr-2`,
                }}
                onClick={e => onClickDeleteAddress(e, address.id)}
                {...{
                  ...(deleteAddressId === address.id
                    ? { isLoading: deleteAddresIsLoading }
                    : {}),
                }}
                data-cy="address__button-delete"
              >
                {t('$*common.delete', 'Usuń')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
