import { cloneElement, useState } from 'react';
import { css } from '@emotion/react';
import { faCircleNotch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import tw from 'twin.macro';

import Button from '@components/elements/Button';
import INPUT_IDS from '@constants/inputs/addressInputs';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useCreateUserAddress from '@hooks/useCreateUserAddress';
import useUpdateUserAddress from '@hooks/useUpdateUserAddress';
import { transformApiErrors } from '@services/Api.serviceTS';
import UserService from '@services/User.service';
import { getChildByDisplayName } from '@utils/helpers';

import AddressForm from './AddressForm';
import { AddressFormContextProvider } from './AddressForm.context';
import { useInitialValues, useValidationSchema } from './formikData';

const AddressFormWrapper = ({
  address = {},
  isFirstAddress = false,
  isNewAddress = false,
  showDeleteAddress = false,
  onSuccessAddAddress = () => {},
  onSuccessUpdateAddress = () => {},
  children,
}) => {
  const [hasDeliveryHours, setHasDeliveryHours] = useState(false);
  const [hasAllowedCities, setHasAllowedCities] = useState(false);
  const [hasAllowedStreets, setHasAllowedStreets] = useState(false);
  const [hasAllowedBuildingNumbers, setHasAllowedBuildingNumbers] =
    useState(false);
  const [isIntercomeCodeActive, setIsIntercomCodeActive] = useState(true);
  const { useAddressesWithLessFields } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );

  const { mutateAsync: updateUserAddress } = useUpdateUserAddress();
  const { mutateAsync: createUserAddress } = useCreateUserAddress();

  const handleFormikSubmit = (
    values,
    { resetForm, setStatus, setSubmitting }
  ) => {
    const addressAction = isNewAddress ? createUserAddress : updateUserAddress;
    const { id: addressId, ...restAddress } =
      UserService.transformAddressFormData({
        formData: values,
        allowedFieldsSettings: {
          hasAllowedCities,
          hasAllowedStreets,
          hasAllowedBuildingNumbers,
        },
        useAddressesWithLessFields,
      });

    const addressDataAction = isNewAddress
      ? restAddress
      : { id: addressId, address: restAddress };

    addressAction(addressDataAction)
      .then(address => {
        if (isNewAddress) {
          onSuccessAddAddress(address);
          resetForm();
        } else {
          onSuccessUpdateAddress(address);
        }
      })
      .catch(err => {
        const error = err?.response?.data;
        const apiFieldErrors = transformApiErrors(error);

        setStatus({
          apiErrorMessage: error?.message ?? error?.['hydra:description'],
          apiFieldErrors,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const validationSchema = useValidationSchema({
    hasDeliveryHours,
    hasAllowedCities,
    hasAllowedStreets,
    isIntercomeCodeActive,
    hasAllowedBuildingNumbers,
  });
  const userAddressesInitialValues = useInitialValues(address, isFirstAddress);

  const isDefaultAddress = userAddressesInitialValues[INPUT_IDS.SET_AS_DEFAULT];
  const DeleteButtonChildren = getChildByDisplayName(children, 'DeleteButton');
  const deleteButtonWithValues = values => {
    if (showDeleteAddress) {
      return cloneElement(DeleteButtonChildren, { values });
    }

    return null;
  };

  return (
    <Formik
      initialValues={userAddressesInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormikSubmit}
      enableReinitialize={true}
      validateOnBlur={false}
    >
      <Form autoComplete="off" data-cy="address-form">
        <AddressFormContextProvider
          {...{
            address,
            setHasDeliveryHours,
            setHasAllowedCities,
            setHasAllowedStreets,
            isIntercomeCodeActive,
            useAddressesWithLessFields,
            setHasAllowedBuildingNumbers,
          }}
        >
          <AddressForm
            address={address}
            deleteButtonWithValues={deleteButtonWithValues}
            isDefaultAddress={isDefaultAddress}
            isFirstAddress={isFirstAddress}
            isNewAddress={isNewAddress}
            showDeleteAddress={showDeleteAddress}
            isIntercomeCodeActive={isIntercomeCodeActive}
            setIsIntercomCodeActive={setIsIntercomCodeActive}
          />
        </AddressFormContextProvider>
      </Form>
    </Formik>
  );
};

const DeleteButton = ({
  onClick = () => {},
  label = '',
  isDeleteLoading,
  values,
}) => (
  <Button type="button" color="error" onClick={() => onClick(values)}>
    <FontAwesomeIcon
      icon={isDeleteLoading ? faCircleNotch : faTrash}
      tw="text-xs"
      css={css`
        ${tw`mr-3`};
        ${isDeleteLoading && tw`animate-spin`}
      `}
    />
    <span>{label}</span>
  </Button>
);

AddressFormWrapper.DeleteButton = DeleteButton;
DeleteButton.displayName = 'DeleteButton';

export default AddressFormWrapper;
