import { useQuery } from '@tanstack/react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/axiosInstance';

const getAllowedCities = async postCode => {
  const response = await axios.get(ENDPOINT.ALLOWED_CITIES, {
    params: { postCode },
    errorHandle: false,
  });

  return response?.data ?? {};
};

const useAllowedCities = (postCode, options = {}) => {
  return useQuery({
    queryKey: ['allowedCities', postCode],
    queryFn: () => getAllowedCities(postCode),
    initialStale: true,
    ...options,
  });
};

export default useAllowedCities;
