import { useTranslation } from 'next-i18next';

import { createContext, useContext } from 'react';
import { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';

import INPUT_IDS from '@constants/inputs/addressInputs';
import useAllowedBuildingNumbers from '@hooks/useAllowedBuildingNumbers';
import useAllowedCities from '@hooks/useAllowedCities';
import useAllowedStreets from '@hooks/useAllowedStreets';
import {
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useDebounce from '@hooks/useDebounce';
import useDeliveryHours from '@hooks/useDeliveryHours';
import {
  transformAllowedBuildingNumbersToArrayOptions,
  transformAllowedCitiesToArrayOptions,
  transformAllowedStreetsToArrayOptions,
  transformDeliveryHoursToArrayOptions,
} from '@services/Api.service';
import { transformApiErrors } from '@services/Api.serviceTS';

const AddressFormContext = createContext([{}, () => {}]);

const AddressFormContextProvider = ({
  address,
  children,
  setHasDeliveryHours,
  setHasAllowedCities,
  setHasAllowedStreets,
  isIntercomeCodeActive,
  useAddressesWithLessFields,
  setHasAllowedBuildingNumbers,
}) => {
  const { t } = useTranslation();
  const formikContext = useFormikContext();
  const { supportedRegions = [] } = useAppConfigSelector(selectMultinational);
  const {
    dirty,
    errors,
    values,
    status,
    touched,
    setStatus,
    isSubmitting,
    setFieldValue,
    validateField,
    setFieldTouched,
  } = formikContext;

  const postCodeRegExpString =
    Object.values(supportedRegions ?? {})?.length === 1
      ? Object.values(supportedRegions)[0]?.postCodeRegexp
      : null;

  const postcodeRegExp = new RegExp(postCodeRegExpString);

  //DEBOUNCED BUILDING NUMBER
  const debouncedBuildingNumber = useDebounce(values?.buildNumber);

  // DEBOUNCED POST CODE - to fetch allowed cities
  const debouncedPostcode = useDebounce(values.postCode);
  const isDebouncedPostcodeValid = postcodeRegExp
    ? postcodeRegExp.test(debouncedPostcode)
    : true;

  // CITY OPTION - to fetch allowed streets
  const cityOption = values?.cityOption?.value;
  const isCityOptionValid = !isEmpty(cityOption);

  // STREET OPTION - to fetch allowed building numbers
  const streetOption = values?.streetOption?.value;
  const isStreetOptionValid = !isEmpty(streetOption);

  // BUILDING NUMBER OPTION
  const buildingNumberOption = values?.buildNumberOption?.value;

  const areAllowedStreetsNeeded = isDebouncedPostcodeValid && isCityOptionValid;
  const areAllowedBuildingNumbersNeeded =
    isDebouncedPostcodeValid && isCityOptionValid && isStreetOptionValid;

  const { data: allowedCitiesData, error: allowedCitiesError } =
    useAllowedCities(debouncedPostcode, {
      enabled: isDebouncedPostcodeValid,
    });

  const { data: allowedStreetsData } = useAllowedStreets(
    { postCode: debouncedPostcode, city: cityOption },
    {
      enabled: areAllowedStreetsNeeded,
    }
  );

  const { data: allowedBuildingNumbersData } = useAllowedBuildingNumbers(
    { postCode: debouncedPostcode, city: cityOption, street: streetOption },
    {
      enabled: areAllowedBuildingNumbersNeeded,
    }
  );

  const allowedCities = useMemo(
    () => transformAllowedCitiesToArrayOptions(allowedCitiesData),
    [allowedCitiesData]
  );
  const allowedStreets = useMemo(
    () =>
      transformAllowedStreetsToArrayOptions(
        areAllowedStreetsNeeded ? allowedStreetsData : []
      ),
    [allowedStreetsData, areAllowedStreetsNeeded]
  );
  const allowedBuildingNumbers = useMemo(
    () =>
      transformAllowedBuildingNumbersToArrayOptions(
        areAllowedBuildingNumbersNeeded ? allowedBuildingNumbersData : []
      ),
    [allowedBuildingNumbersData, areAllowedBuildingNumbersNeeded]
  );

  const cityForDeliveryHours = isEmpty(allowedCities)
    ? values?.city
    : values?.cityOption?.value;
  const streetForDeliveryHours = useAddressesWithLessFields
    ? 'useLessFields'
    : isEmpty(allowedStreets)
    ? values?.street
    : values?.streetOption?.value;
  const buildingNumberForDeliveryHours = useAddressesWithLessFields
    ? 'useLessFields'
    : isEmpty(allowedBuildingNumbers)
    ? debouncedBuildingNumber
    : values?.buildNumberOption?.value;

  const areDeliveryHoursEnabled =
    isDebouncedPostcodeValid &&
    !isEmpty(cityForDeliveryHours) &&
    !isEmpty(streetForDeliveryHours) &&
    !isEmpty(buildingNumberForDeliveryHours);

  const {
    data,
    error: deliveryHoursError,
    isLoading: deliveryHoursIsLoading,
    isFetching: deliveryHoursIsFetching,
  } = useDeliveryHours(
    {
      postCode: debouncedPostcode,
      city: cityForDeliveryHours,
      street: streetForDeliveryHours,
      building: buildingNumberForDeliveryHours,
    },
    {
      enabled: areDeliveryHoursEnabled,
    }
  );

  const hours = useMemo(
    () => transformDeliveryHoursToArrayOptions(data?.hours, t),
    [data?.hours]
  );

  const hasDeliveryHours = !isEmpty(hours);
  const hasAllowedCities = !isEmpty(allowedCities);
  const hasAllowedStreets = !isEmpty(allowedStreets);
  const hasAllowedBuildingNumbers = !isEmpty(allowedBuildingNumbers);

  useEffect(() => {
    setHasDeliveryHours(hasDeliveryHours);
    setHasAllowedCities(hasAllowedCities);
    setHasAllowedStreets(hasAllowedStreets);
    setHasAllowedBuildingNumbers(hasAllowedBuildingNumbers);
  }, [
    hasDeliveryHours,
    hasAllowedCities,
    hasAllowedStreets,
    hasAllowedBuildingNumbers,
  ]);

  useEffect(() => {
    const selectedDeliveryHour = values?.selectedDeliveryHour?.value;

    const isSelectedDeliveryHourAvaliable =
      !isEmpty(selectedDeliveryHour) &&
      !isEmpty(hours.find(({ value }) => value === selectedDeliveryHour));

    if (
      isEmpty(selectedDeliveryHour) ||
      (!isSelectedDeliveryHourAvaliable && !isEmpty(hours))
    ) {
      const firstHours = (hours ?? []).find(hour => hour?.default) ??
        hours?.[0] ?? { label: '', value: '' };
      setFieldValue(INPUT_IDS.DELIVERY_HOURS, firstHours);
    }
  }, [hours]);

  // Handle selected city update when allowedCities are updated
  useEffect(() => {
    if (isEmpty(allowedCities)) return;

    const isSelectedCityAvaliable =
      cityOption === address.city ||
      (!isEmpty(cityOption) &&
        !isEmpty(allowedCities.find(({ label }) => label === cityOption)));

    if (isEmpty(cityOption) || !isSelectedCityAvaliable) {
      setFieldValue(INPUT_IDS.CITY_OPTION, { label: '', value: '' });
    }
  }, [allowedCities]);

  // Handle selected STREETS update when allowedStreets are updated
  useEffect(() => {
    if (isEmpty(allowedStreets)) return;

    const isSelectedStreetAvaliable =
      streetOption === address?.street ||
      (!isEmpty(streetOption) &&
        !isEmpty(allowedStreets.find(({ label }) => label === streetOption)));

    if (isEmpty(streetOption) || !isSelectedStreetAvaliable) {
      setFieldValue(INPUT_IDS.STREET_OPTION, { label: '', value: '' });
    }
  }, [allowedStreets]);

  // Handle selected BUILDING NUMBER update when allowedBuildingNumbers are updated
  useEffect(() => {
    if (isEmpty(allowedBuildingNumbers)) return;

    const isSelectedBuildingNumberAvaliable =
      buildingNumberOption === address.buildNumber ||
      (!isEmpty(buildingNumberOption) &&
        !isEmpty(
          allowedBuildingNumbers.find(
            ({ label }) => label === buildingNumberOption
          )
        ));

    if (isEmpty(buildingNumberOption) || !isSelectedBuildingNumberAvaliable) {
      setFieldValue(INPUT_IDS.HOUSE_NUMBER_OPTION, {
        label: '',
        value: '',
      });
    }
  }, [allowedBuildingNumbers]);

  useEffect(() => {
    const errorData =
      deliveryHoursError?.response?.data || allowedCitiesError?.response?.data;

    let apiFieldErrors = !isEmpty(errorData)
      ? transformApiErrors(errorData)
      : {};

    if (!isEmpty(apiFieldErrors?.city)) {
      apiFieldErrors = {
        ...apiFieldErrors,
        [INPUT_IDS.CITY_OPTION]: apiFieldErrors?.city,
      };
    }
    if (!isEmpty(apiFieldErrors?.street)) {
      apiFieldErrors = {
        ...apiFieldErrors,
        [INPUT_IDS.STREET_OPTION]: apiFieldErrors?.street,
      };
    }
    if (!isEmpty(apiFieldErrors?.building)) {
      apiFieldErrors = {
        ...apiFieldErrors,
        [INPUT_IDS.HOUSE_NUMBER_OPTION]: apiFieldErrors?.building,
      };
    }

    setStatus({
      apiFieldErrors,
    });
  }, [deliveryHoursError, allowedCitiesError]);

  useEffect(() => {
    // CLEAR INTERCOME CODE IF IS NOT ACTIVE
    if (!isIntercomeCodeActive) {
      setFieldValue(INPUT_IDS.INTERCOM_CODE, '');
    }
  }, [isIntercomeCodeActive]);

  const disableEditField = address?.containsActiveDiets ?? false;

  const isCityFieldEnabled = !disableEditField && !isEmpty(allowedCitiesData);
  const isStreetFieldEnabled =
    !disableEditField &&
    isCityFieldEnabled &&
    !(!isEmpty(allowedCities) && !isCityOptionValid) &&
    (!areAllowedStreetsNeeded ||
      (areAllowedStreetsNeeded && !isEmpty(allowedStreetsData)));

  const isBuildNumberFieldEnabled =
    !disableEditField &&
    isStreetFieldEnabled &&
    !(!isEmpty(allowedStreets) && !isStreetOptionValid) &&
    (!areAllowedBuildingNumbersNeeded ||
      (areAllowedBuildingNumbersNeeded &&
        !isEmpty(allowedBuildingNumbersData)));

  const value = {
    hours,
    errors,
    values,
    status,
    touched,
    isFormDirty: dirty,
    allowedCities,
    validateField,
    setFieldTouched,
    setFieldValue,
    isFormSubmitting: isSubmitting,
    allowedStreets,
    hasDeliveryHours,
    hasAllowedCities,
    debouncedPostcode,
    hasAllowedStreets,
    allowedBuildingNumbers,
    deliveryHoursIsLoading,
    hasAllowedBuildingNumbers,
    isCityFieldEnabled,
    isStreetFieldEnabled,
    deliveryHoursIsFetching,
    areDeliveryHoursEnabled,
    isBuildNumberFieldEnabled,
  };

  return (
    <AddressFormContext.Provider value={value}>
      {children}
    </AddressFormContext.Provider>
  );
};

const useAddressFormContext = () => useContext(AddressFormContext);

export { AddressFormContextProvider, useAddressFormContext };
