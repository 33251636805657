import { useQuery } from '@tanstack/react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/axiosInstance';

const getAllowedStreets = async payload => {
  const response = await axios.get(ENDPOINT.ALLOWED_STREETS, {
    params: { ...payload },
    errorHandle: false,
  });

  return response?.data ?? {};
};

const useAllowedStreets = (payload, options = {}) => {
  return useQuery({
    queryKey: ['allowedStreets', payload],
    queryFn: () => getAllowedStreets(payload),
    initialStale: true,
    ...options,
  });
};

export default useAllowedStreets;
