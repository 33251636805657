import { useTranslation } from 'next-i18next';

import { Fragment, useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import tw, { theme } from 'twin.macro';

import Button from '@components/elements/Button';
import Checkbox from '@components/elements/Form/Checkbox';
import InputsIndex from '@components/elements/Form/InputsIndex';
import INPUT_IDS from '@constants/inputs/addressInputs';
import {
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useMediaQuery from '@hooks/useMediaQuery';
import { up } from '@utils/screens';

import { useAddressFormContext } from './AddressForm.context';
import useFields from './useFields';

const AddressForm = ({
  address,
  deleteButtonWithValues,
  isDefaultAddress,
  isFirstAddress,
  isNewAddress,
  showDeleteAddress = false,
  isIntercomeCodeActive,
  setIsIntercomCodeActive,
}) => {
  const { t } = useTranslation();
  const postCodeRef = useRef();

  const [clickedDisabledDeliveryHours, setClickedDisabledDeliveryHours] =
    useState(false);
  const [fields, [fieldSetAsDefault]] = useFields(address);
  const isUpMd = useMediaQuery(up('md'), true);
  const {
    hours,
    status,
    errors,
    values,
    touched,
    isFormDirty: dirty,
    allowedCities,
    validateField,
    setFieldTouched,
    isFormSubmitting: isSubmitting,
    allowedStreets,
    hasDeliveryHours,
    hasAllowedCities,
    hasAllowedStreets,
    isCityFieldEnabled,
    isStreetFieldEnabled,
    deliveryHoursIsLoading,
    allowedBuildingNumbers,
    deliveryHoursIsFetching,
    areDeliveryHoursEnabled,
    isBuildNumberFieldEnabled,
    hasAllowedBuildingNumbers,
    setFieldValue,
  } = useAddressFormContext();
  const { supportedRegions = [] } = useAppConfigSelector(selectMultinational);

  useEffect(() => {
    if (isNewAddress) {
      postCodeRef?.current?.focus();
    }
  }, []);

  const DynamicHeader = () => {
    const Title = isDefaultAddress && (
      <h2 tw="mb-0 text-primary">
        {t('$*components.addressForm.defaultAddress', 'Adres domyślny')}
      </h2>
    );

    const DeleteButton = !isDefaultAddress && showDeleteAddress && (
      <div tw="self-end">{deleteButtonWithValues(values)}</div>
    );

    if (isEmpty(Title) && isEmpty(DeleteButton)) {
      return null;
    }

    return (
      <div tw="flex flex-col mb-4">
        {Title}
        {DeleteButton}
      </div>
    );
  };

  // Here we check if post code is valid
  // to set proper styles of address disabled fields
  // ----------------------------------------------------------------
  const hasPostCodeError =
    (errors?.[INPUT_IDS.POSTAL_CODE] && touched?.[INPUT_IDS.POSTAL_CODE]) ??
    false;
  const apiFieldError = status?.apiFieldErrors?.[INPUT_IDS.POSTAL_CODE] || '';
  const hasPostCodeApiFieldError = !isEmpty(apiFieldError);
  const isPostCodeInvalid = hasPostCodeError || hasPostCodeApiFieldError;
  const hasCityOptionError =
    (errors?.[INPUT_IDS.CITY_OPTION] && touched?.[INPUT_IDS.CITY_OPTION]) ??
    false;
  const hasStreetOptionError =
    (errors?.[INPUT_IDS.STREET_OPTION] && touched?.[INPUT_IDS.STREET_OPTION]) ??
    false;

  const customTextInputDisabledStyle = isNewAddress
    ? isPostCodeInvalid || hasCityOptionError || hasStreetOptionError
      ? tw`text-gray-3 bg-gray-0 border-gray-1 cursor-not-allowed`
      : tw`text-gray-4 bg-white border-gray-1`
    : null;

  const postCodeInputMask =
    Object.values(supportedRegions ?? {})?.length === 1
      ? Object.values(supportedRegions)[0]?.postCodeMask
      : null;

  // ----------------------------------------------------------------

  return (
    <Fragment>
      <DynamicHeader />

      {address?.containsActiveDiets && (
        <p>
          {t(
            '$*components.addressForm.cannotEditAddressWithOtherDiet',
            'Nie możesz edytować niektórych pól adresu, na którym masz aktywną inną dietę.'
          )}
        </p>
      )}

      <div className="row">
        <input autoComplete="off" name="hidden" type="search" tw="hidden" />
        {fields.map(
          ({
            colClass = 'col-12',
            colCss = tw`order-none`,
            id,
            ...restProps
          }) => {
            let additionalProps = {};
            let newColClass =
              !hasDeliveryHours && [INPUT_IDS.ADDRESS_LINE2].includes(id)
                ? 'col-12'
                : colClass;

            if (id === INPUT_IDS.DELIVERY_HOURS) {
              additionalProps = {
                ...(hasDeliveryHours ? { options: hours } : {}),
                deliveryHoursIsLoading,
              };

              return (
                <div
                  className={newColClass}
                  key={id}
                  css={colCss}
                  onClick={() => {
                    setClickedDisabledDeliveryHours(true);
                  }}
                >
                  <InputsIndex
                    id={id}
                    {...restProps}
                    {...additionalProps}
                    disabled={!hasDeliveryHours}
                    customDisabledStyles={
                      clickedDisabledDeliveryHours
                        ? {
                            background: theme`colors.gray.0`,
                            borderColor: theme`colors.gray.1`,
                          }
                        : {
                            background: theme`colors.white`,
                            borderColor: theme`colors.gray.1`,
                          }
                    }
                    description={
                      !hasDeliveryHours &&
                      !deliveryHoursIsFetching &&
                      areDeliveryHoursEnabled
                        ? t(
                            '$*components.addressForm.deliveryHoursUnavaliable',
                            'Wybór czasu dostawy nie jest możliwy dla tego adresu'
                          )
                        : t(
                            '$*components.addressForm.deliveryHoursDependOnAddress',
                            'Godziny dostawy są zależne od  adresu dostawy'
                          )
                    }
                  />
                </div>
              );
            }

            if (id === INPUT_IDS.INTERCOM_CODE) {
              const hasIntercomError = (errors?.[id] && touched?.[id]) ?? false;
              const hasIntercomApiFieldError = !isEmpty(
                status?.apiFieldErrors?.[id] || ''
              );

              const isIntercomInvalid =
                hasIntercomError || hasIntercomApiFieldError;

              return (
                <div className={newColClass} key={id} css={colCss}>
                  <InputsIndex
                    id={id}
                    {...restProps}
                    {...additionalProps}
                    isFieldHidden={!isIntercomeCodeActive}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  {isEmpty(values?.keyToIntercom) && (
                    <Checkbox
                      id={`toggle${id
                        .substring(0, 1)
                        .toUpperCase()}${id.substring(1)}`}
                      value={!isIntercomeCodeActive}
                      label={t(
                        '$*components.addressForm.noIntercomeCode',
                        'Nie posiadam kodu i/lub domofonu'
                      )}
                      readOnly
                      onClick={e => {
                        e.stopPropagation();
                        setIsIntercomCodeActive(!isIntercomeCodeActive);
                      }}
                      styles={{
                        input: {
                          css: {
                            ...(isIntercomInvalid && tw`border-red-2`),
                          },
                        },
                        label: {
                          css: {
                            ...tw`mb-3 text-sm`,
                            ...(isIntercomInvalid && tw`text-red-2`),
                          },
                        },
                      }}
                    />
                  )}
                </div>
              );
            }

            if (id === INPUT_IDS.POSTAL_CODE) {
              return (
                <div className={newColClass} key={id} css={colCss}>
                  <InputsIndex
                    id={id}
                    innerRef={postCodeRef}
                    onKeyDown={e => {
                      if (e.code === 'Tab' && !isCityFieldEnabled)
                        e.preventDefault();
                    }}
                    mask={
                      !isEmpty(postCodeInputMask) ? postCodeInputMask[0] : null
                    }
                    placeholder={
                      !isEmpty(postCodeInputMask)
                        ? postCodeInputMask[0].toUpperCase()
                        : null
                    }
                    {...restProps}
                    {...additionalProps}
                  />
                </div>
              );
            }

            if ([INPUT_IDS.CITY, INPUT_IDS.CITY_OPTION].includes(id)) {
              additionalProps = {
                ...(hasAllowedCities ? { options: allowedCities } : {}),
              };

              return (
                ((hasAllowedCities && id === INPUT_IDS.CITY_OPTION) ||
                  (!hasAllowedCities && id === INPUT_IDS.CITY)) && (
                  <div className={newColClass} key={id} css={colCss}>
                    <InputsIndex
                      id={id}
                      {...restProps}
                      {...additionalProps}
                      onKeyDown={e => {
                        if (e.code === 'Tab' && !isStreetFieldEnabled)
                          e.preventDefault();
                      }}
                      autoComplete="off"
                      disabled={!isCityFieldEnabled}
                      customDisabledStyles={customTextInputDisabledStyle}
                    />
                  </div>
                )
              );
            }

            if ([INPUT_IDS.STREET, INPUT_IDS.STREET_OPTION].includes(id)) {
              additionalProps = {
                ...(hasAllowedStreets ? { options: allowedStreets } : {}),
              };

              return (
                ((hasAllowedStreets && id === INPUT_IDS.STREET_OPTION) ||
                  (!hasAllowedStreets && id === INPUT_IDS.STREET)) && (
                  <div
                    className={newColClass}
                    key={id}
                    css={colCss}
                    onClick={() => {
                      if (isCityFieldEnabled && !isStreetFieldEnabled) {
                        setFieldTouched(INPUT_IDS.CITY_OPTION);
                        validateField(INPUT_IDS.CITY_OPTION);
                      }
                    }}
                  >
                    <InputsIndex
                      id={id}
                      {...restProps}
                      {...additionalProps}
                      autoComplete="off"
                      onKeyDown={e => {
                        if (e.code === 'Tab' && !isBuildNumberFieldEnabled)
                          e.preventDefault();
                      }}
                      disabled={!isStreetFieldEnabled}
                      customDisabledStyles={customTextInputDisabledStyle}
                    />
                  </div>
                )
              );
            }

            if (
              [INPUT_IDS.HOUSE_NUMBER, INPUT_IDS.HOUSE_NUMBER_OPTION].includes(
                id
              )
            ) {
              additionalProps = {
                ...(hasAllowedBuildingNumbers
                  ? { options: allowedBuildingNumbers }
                  : {}),
              };

              return (
                ((hasAllowedBuildingNumbers &&
                  id === INPUT_IDS.HOUSE_NUMBER_OPTION) ||
                  (!hasAllowedBuildingNumbers &&
                    id === INPUT_IDS.HOUSE_NUMBER)) && (
                  <div
                    className={newColClass}
                    key={id}
                    css={colCss}
                    onClick={() => {
                      if (isStreetFieldEnabled && !isBuildNumberFieldEnabled) {
                        setFieldTouched(INPUT_IDS.STREET_OPTION);
                        validateField(INPUT_IDS.STREET_OPTION);
                      }
                    }}
                  >
                    <InputsIndex
                      id={id}
                      {...restProps}
                      {...additionalProps}
                      autoComplete="off"
                      disabled={!isBuildNumberFieldEnabled}
                      customDisabledStyles={customTextInputDisabledStyle}
                    />
                  </div>
                )
              );
            }

            if (id === INPUT_IDS.ADDITIONAL_INFO) {
              return (
                <div className={newColClass} key={id} css={colCss}>
                  <InputsIndex
                    id={id}
                    {...restProps}
                    {...additionalProps}
                    description={t(
                      '$*input.additionalInfo.description',
                      'Podaj wskazówki, które ułatwią kurierowi dostarczenie zamówienia.'
                    )}
                  />
                </div>
              );
            }

            return (
              <div className={newColClass} key={id} css={colCss}>
                <InputsIndex id={id} {...restProps} {...additionalProps} />
              </div>
            );
          }
        )}
      </div>

      {errors?.apiErrorMessage && (
        <div tw="mt-4 bg-red-0 border border-red-2 text-red-2 px-4 py-2 rounded text-sm">
          {/* i18next-extract-disable-next-line */}
          {t(`$*error.api.${errors.apiErrorMessage}`)}
        </div>
      )}

      <div className="row" tw="mt-4 justify-between items-center">
        <div className="sm:col-auto">
          {!isDefaultAddress && !isFirstAddress && (
            <InputsIndex {...fieldSetAsDefault} />
          )}
        </div>
        <div className="sm:col-auto">
          <Button
            type="submit"
            isLoading={isSubmitting}
            disabled={
              isSubmitting || !dirty || !isEmpty(status?.apiFieldErrors)
            }
            fullWidth={!isUpMd}
            styles={{ button: tw`mt-8 sm:mt-0` }}
            data-cy="address-form__submit"
          >
            {isNewAddress
              ? t('$*components.addressForm.saveAddress', 'Zapisz adres')
              : t('$*common.updateData', 'Zaktualizuj dane')}
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default AddressForm;
